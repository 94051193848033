/*!
 * mLeasing, footer portlet link group :: 26/01/2021
 * Copyright (C) QUERCUS, https://qrqs.eu
 */

import React from 'react';
import styled from 'styled-components';
import {Stack} from '@mbank-design/design-system/components';
import {Text} from '@mbank-design/design-system/components';
import {Icon} from '@mbank-design/design-system/components';
import {PhoneHandleRinging} from '@mbank-design/design-system/icons';
import {HumanChat} from '@mbank-design/design-system/icons';
import {Envelope} from '@mbank-design/design-system/icons';
import {MapMarker} from '@mbank-design/design-system/icons';
import {ChevronDown} from '@mbank-design/design-system/icons';
import {ChevronUp} from '@mbank-design/design-system/icons';
import {IconColor} from '@mbank-design/design-system/enums';
import {Spacing} from '@mbank-design/design-system/enums';
import {Direction} from '@mbank-design/design-system/enums';
import {px} from '@mbank-design/design-system/utils';
import breakpoints from '@mbank-design/design-system/styles/breakpoints';
import palette from '@mbank-design/design-system/palette';
import {generateLink} from '../../utils/generate-link.js';

const version = 'v: 1.0.2 :: r. 09/10/24 @ pp';
const FooterListItem = styled.li`
	flex-basis: 25%;
	padding-left: ${px(Spacing.SPACE_12)};
	padding-right: ${px(Spacing.SPACE_12)};
	box-sizing: border-box;
	${props => {
		if (props.itemIndex === 0) {
			return `
				padding-left: 0;
			`;
		}
		if (props.itemIndex === 3) {
			return `
				padding-right: 0;
			`;
		}
	}}

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		padding-right: ${px(Spacing.SPACE_16)};
		padding-left: ${px(Spacing.SPACE_16)};
		${props => {
			if (props.exp) {
				return `
					position: relative;	
				`;
			}
		}}
	}
`;
const GroupList = styled.ul`
	list-style: none;
	padding-top: ${px(Spacing.SPACE_20)};
	padding-left: ${px(Spacing.SPACE_0)};
	margin-top: ${px(Spacing.SPACE_20)};
	border-top: 1px solid grey;

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		padding-bottom: ${px(Spacing.SPACE_20)};
		${props => {
			if (props.exp === 'true' && !props.open) {
				return `
					height: ${px(Spacing.SPACE_0)};
					overflow: hidden;
					padding-bottom: ${px(Spacing.SPACE_0)};
				`;
			}
			if (props.exp === 'true' && props.open) {
				return `
					height: auto;
					overflow: hidden;
					padding-bottom: ${px(Spacing.SPACE_0)};
				`;
			}
		}}
	}
`;
const GroupListItem = styled.li`
	display: block;
	margin-bottom: ${px(Spacing.SPACE_0)};

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		margin-bottom: ${px(Spacing.SPACE_4)};
	}
`;
const ChevronContainer = styled.div`
	display: none;
	position: absolute;
	top: ${px(Spacing.SPACE_8)};
	right: ${px(Spacing.SPACE_16)};

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		display: block;
	}
`;
const LinkWrapper = styled.div`
	display: flex;
	align-items: center;
`;
const StyledLink = styled.a`
	display: block;
	height: 36px;
	padding-top: 5px;
	color: ${palette.zeusBlackUI};
`;
const linkStyles = `
	display: block;
	height: 36px;
	padding-top: 5px;
	color: ${palette.zeusBlackUI};
`;
const buildState = (props, state) => {
	return {
		offline:  props.offline,
		expandable: props.data ? props.data.expandable : false,
		links: props.data ? props.data.links : [],
		groupTitle: props.data ? props.data.groupTitle : '',
		itemIndex: props.itemIndex,
		open: state ? state.open : false
	};
};

class LinkGroup extends React.Component {

	constructor(props) {
		super(props);
		this.getVersion = this.getVersion.bind(this);
		this.renderLinkWithIcon = this.renderLinkWithIcon.bind(this);
		this.toggleExpandable = this.toggleExpandable.bind(this);
		this.fastCallClick = this.fastCallClick.bind(this);

		this.state = buildState(props);
	}

	getVersion() {
		return version;
	}

	componentDidMount() {
		if (window.origin.includes('localhost') || window.origin.includes('test-mleasing11')) {
			console.log('footer');
		}
	}

	static getDerivedStateFromProps(props, state) {
		return buildState(props, state);
	}

	fastCallClick(e) {
		e.preventDefault();
		document.querySelector('.cp-widget-button__inner').click();
	}

	// render different type of icon depending on data
	renderLinkWithIcon(link) {

		let icon = '';

		if (link.icon === 'phone') {
			icon = <Icon iconComponent={PhoneHandleRinging} primaryColor={IconColor.BLUE} titel="telefon" />;
		}
		if (link.icon === 'chat') {
			icon = <Icon iconComponent={HumanChat} primaryColor={IconColor.BLUE} title="chat" />;
		}
		if (link.icon === 'mail') {
			icon = <Icon iconComponent={Envelope} primaryColor={IconColor.BLUE} title="email" />;
		}
		if (link.icon === 'location') {
			icon = <Icon iconComponent={MapMarker} primaryColor={IconColor.BLUE} title="lokalizacja" />;
		}

		return (
			<LinkWrapper className="link-wrapper">
				<Stack spacing={Spacing.SPACE_8} direction={Direction.ROW}>
					{icon}
					<Text as="p" color="endeavourBlueUI">{link.linkName}</Text>
				</Stack>
			</LinkWrapper>
		);
	}

	// toggle expandable footer group on mobile devices
	toggleExpandable() {
		if (this.state.expandable === 'true') {
			this.setState({
				expandable: this.state.expandable,
				links: this.state.links,
				groupTitle: this.state.groupTitle,
				open: !this.state.open
			});
		}
	}

	render() {
		return (
			<FooterListItem itemIndex={this.state.itemIndex} exp={this.state.expandable}>
				{this.state.expandable === 'true' && <ChevronContainer className="chevron">
					<Icon
						iconComponent={this.state.open ? ChevronUp : ChevronDown}
						primaryColor={IconColor.BLUE}
						title="ikona"
					/>
				</ChevronContainer>}
				<Text as="h2" className="group-title" onClick={this.toggleExpandable}>{this.state.groupTitle}</Text>
				<GroupList className="group" open={this.state.open} exp={this.state.expandable}>{this.state.links.length && this.state.links.map((link, j) => {
					return (<GroupListItem key={'footer-link' + j}>
						{link.icon !== 'phone' ? generateLink(this.props.prefix + link.link, '', linkStyles, (
							link.icon && link.icon !== 'none' ? this.renderLinkWithIcon(link) : <Text as="p">{link.linkName}</Text>
						), this.state.offline, link.openInNewTab) : ''}
						{link.icon === 'phone' ? <StyledLink
							href="#"
							className="fastcall"
							onClick={this.fastCallClick}
						>
							{this.renderLinkWithIcon(link)}
						</StyledLink> : ''}
					</GroupListItem>);
				})}</GroupList>
			</FooterListItem>
		);
	}

}

export default LinkGroup;
