/*!
 * mLeasing, navigation component :: 07/01/2021
 * Copyright (C) QUERCUS, https://qrqs.eu
 */

import React from 'react';
import {Link} from '@reach/router';
import styled from 'styled-components';
import {environment} from '../../environment.js';
import {mapLinks} from '../utils/map-links.js';
import {calculateSiteHeight} from '../utils/calculate-site-height.js';
import {MbankLeasing as Logo} from '@mbank-design/design-system/logos';
import Actions from './actions.js';
import Container from './container.js';
import MenuBox from './menu-box.js';
import {Stripe} from '@mbank-design/design-system/components';
import {Icon} from '@mbank-design/design-system/components';
import {IconColor} from '@mbank-design/design-system/enums';
import {ButtonBase} from '@mbank-design/design-system/components';
import {Text} from '@mbank-design/design-system/components';
import {HamburgerMenu} from '@mbank-design/design-system/icons';
import {Close} from '@mbank-design/design-system/icons';
import {ChevronDown} from '@mbank-design/design-system/icons';
import {ChevronUp} from '@mbank-design/design-system/icons';
import {ChevronLeft} from '@mbank-design/design-system/icons';
import {ChevronRight} from '@mbank-design/design-system/icons';
import {StripeHeight} from '@mbank-design/design-system/enums';
import {Spacing} from '@mbank-design/design-system/enums';
import {px} from '@mbank-design/design-system/utils';
import breakpoints from '@mbank-design/design-system/styles/breakpoints';
import palette from '@mbank-design/design-system/palette';
import {generateLink} from '../utils/generate-link.js';

const version = 'v: 1.0.2 :: r. 02/10/24 @ pp';
const MainNav = styled.nav`
	display: flex;
	align-items: center;
	padding-bottom: ${px(Spacing.SPACE_16)};
	padding-top: ${px(Spacing.SPACE_16)};
	max-width: ${px(1315)};
	margin: 0 auto;
	@media screen and (max-width: ${px(1315)}) {
		width: 100vw;
		margin-left: 0;
	}
	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		padding-right: ${px(Spacing.SPACE_16)};
		padding-left: ${px(Spacing.SPACE_16)};
	}
`;
const NavToggle = styled.div`
	display: none;
	justify-content: center;
	align-items: center;
	height: ${px(Spacing.SPACE_50)};
	width: ${px(Spacing.SPACE_50)};
	margin-left: auto;
	cursor: pointer;

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		display: flex;
		position: absolute;
		right: ${px(Spacing.SPACE_16)};
	}
`;
const Menu = styled.ul`
	list-style: none;
	display: flex;
	margin-right: auto;
	padding-left: ${px(Spacing.SPACE_24)};

	@media screen and (min-width: ${px(breakpoints.mobileMax)}) and (max-width: 1285px) {
		padding-left: ${px(Spacing.SPACE_20)};
	}
	@media screen and (min-width: ${px(breakpoints.mobileMax)}) and (max-width: 1130px) {
		padding-left: 14px;
	}
	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		flex-direction: column;
		width: 100%;
		padding-right: ${px(Spacing.SPACE_16)};
		padding-left: ${px(Spacing.SPACE_16)};
		margin-top: ${Spacing.SPACE_0};
		margin-bottom: ${Spacing.SPACE_0};
	}
`;
const MenuItem = styled.li`
	margin-left: ${props => props.marginLeft || px(8)};
	margin-right: ${props => props.marginRight || px(8)};
	margin-bottom: ${props => props.marginBottom || 0};
    display: flex;
    align-items: center;

	@media screen and (min-width: ${px(breakpoints.mobileMax)}) and (max-width: 1230px) {
		margin-left: 6px;
		margin-right: 6px;
	}
	@media screen and (min-width: ${px(breakpoints.mobileMax)}) and (max-width: 1230px) {
		margin-left: ${Spacing.SPACE_4};
		margin-right: ${Spacing.SPACE_4};
	}
	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		position: relative;
		margin-right: ${Spacing.SPACE_0};
		margin-left: ${Spacing.SPACE_0};
		margin-bottom: ${Spacing.SPACE_0};
		padding-top: ${px(Spacing.SPACE_12)};
		padding-bottom: ${px(Spacing.SPACE_12)};
		border-bottom: 1px solid ${palette.altoGray};
	}
`;
// toggle visibility based on component props
const SubmenuLayer = styled.div`
	${props => {
		if (props.show) {
			return `
				display: block;
				position: absolute;
				top: ${px(Spacing.SPACE_84)};
				right: ${px(Spacing.SPACE_0)};
				left: ${px(Spacing.SPACE_0)};
				z-index: 2;
				padding-top: ${px(Spacing.SPACE_40)};
				padding-bottom: ${px(Spacing.SPACE_40)};
				background-color: ${palette.solidWhite};
			`;
		} else {
			return `
				display: none;
				position: absolute;
				top: ${px(Spacing.SPACE_84)};
				right: ${px(Spacing.SPACE_0)};
				left: ${px(Spacing.SPACE_0)};
				z-index: 2;
				padding-top: ${px(Spacing.SPACE_40)};
				padding-bottom: ${px(Spacing.SPACE_40)};
				background-color: ${palette.solidWhite};
			`;
		}
	}}

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		position: fixed;
		top: ${px(Spacing.SPACE_84)};
		bottom: ${px(Spacing.SPACE_0)};
		padding-left: ${px(Spacing.SPACE_16)};
		padding-right: ${px(Spacing.SPACE_16)};
	}
`;
const Overlay = styled.div`
	${props => {
		if (props.show) {
			return `
				display: block;
				position: absolute;
				top: ${px(Spacing.SPACE_84)};
				right: ${px(Spacing.SPACE_0)};
				left: ${px(Spacing.SPACE_0)};
				z-index: 1;
				height: ${props.siteHeight + 'px'};
				background-color: ${palette.zeusBlackUI};
				opacity: .2;
			`;
		} else {
			return `
				display: none;
				position: absolute;
				top: ${px(Spacing.SPACE_84)};
				right: ${px(Spacing.SPACE_0)};
				left: ${px(Spacing.SPACE_0)};
				z-index: 1;
				height: ${props.siteHeight + 'px'};
				background-color: ${palette.zeusBlackUI};
				opacity: .2;
			`;
		}
	}}

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		top: 0;
	}
`;
const Submenu = styled.ul`
	list-style: none;
	padding-left: ${px(Spacing.SPACE_0)};

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		display: ${props => props.open ? 'block' : 'none'};
	}
`;
const SubmenuWrapper = styled.div`
	display: flex;
	padding-left: ${props => props.index === 0 ? px(223) : px(395)};

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		flex-wrap: wrap;
		padding-left: ${px(Spacing.SPACE_0)};
	}
`;
const SubmenuContainer = styled.div`
	position: relative;
	max-width: ${px(1315)};
	margin: 0 auto;
`;
const ButtonContent = styled.span`
	position: relative;
	display: flex;
	align-items: center;
	color: ${props => props.color};
	padding-right: ${px(Spacing.SPACE_20)};
	line-height: 1.1
`;
const MobileButtonContent = styled(ButtonContent)`
	display: none;

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		display: block;
	}
`;
const IconWrapper = styled.div`
	position: absolute;
	left: calc(100% - ${px(Spacing.SPACE_20)});
    font-size: 1px;
    line-height: 1;

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		display: none;
	}
`;
const MobileIconWrapper = styled.div`
	position: absolute;
	display: none;
	left: calc(100% - ${px(Spacing.SPACE_24)});
	top: ${px(Spacing.SPACE_12)};

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		display: block;
	}
`;
const SubmenuColumn = styled.div`
	flex-basis: 50%;

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		flex-basis: 100%;
	}
`;
const SubmenuTitle = styled.div`
	display: inline-block;
	margin-bottom: ${px(Spacing.SPACE_24)};
	padding-bottom: ${px(Spacing.SPACE_12)};
	border-bottom: ${px(2)} solid ${palette.endeavourBlueUI};

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		display: none;
	}
`;
const MobileSubmenuTitle = styled.div`
	display: none;
	margin-right: ${Spacing.SPACE_0};
	margin-left: ${Spacing.SPACE_0};
	padding-top: ${px(Spacing.SPACE_12)};
	padding-bottom: ${px(Spacing.SPACE_12)};
	border-bottom: 1px solid ${palette.altoGray};

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		position: relative;
		display: ${props => props.open ? 'block' : 'none'};
	}
`;
const MobileBackLink = styled.div`
	display: none;

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		display: block;
	}
`;
const NavWrapper = styled.div`
	display: flex;
	flex-grow: 1;
	justify-content: space-between;

	@media screen and (min-width: ${px(breakpoints.mobileMax)}) and (max-width: 1300px) {
		font-size: 0.9em;
	}
	@media screen and (min-width: ${px(breakpoints.mobileMax)}) and (max-width: 1230px) {
		font-size: 0.85em;
	}
	@media screen and (min-width: ${px(breakpoints.mobileMax)}) and (max-width: 1170px) {
		font-size: 0.8em;
	}
	@media screen and (min-width: ${px(breakpoints.mobileMax)}) and (max-width: 1130px) {
		font-size: 0.75em;
	}
	@media screen and (min-width: ${px(breakpoints.mobileMax)}) and (max-width: 1070px) {
		font-size: 0.7em;
	}
	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		display: ${props => props.open ? 'flex' : 'none'};
		flex-direction: column;
		position: absolute;
		top: ${px(93)};
		left: 0;
		right: 0;
		height: calc(100vh - ${props => props.offline ? px(145) : px(93)});
		background-color: ${palette.solidWhite};
		z-index: 2;
	}
`;
const NavButton = styled(ButtonBase)`
	font-weight: 600;
	font-family: Montserrat,Arial,sans-serif;

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		width: 100%;
	}
`;

class Navigation extends React.Component {

	constructor(props) {
		super(props);
		this.getVersion = this.getVersion.bind(this);
		this.offlineClickHandler = this.offlineClickHandler.bind(this);
		this.generateNav = this.generateNav.bind(this);
		this.generateSubmenu = this.generateSubmenu.bind(this);
		this.toggleMobileNav = this.toggleMobileNav.bind(this);
		this.showSubmenu = this.showSubmenu.bind(this);
		this.hideSubmenu = this.hideSubmenu.bind(this);
		this.toggleSubmenu = this.toggleSubmenu.bind(this);
		this.mapLinkCategories = this.mapLinkCategories.bind(this);
		this.openCategory = this.openCategory.bind(this);
		this.hideCategory = this.hideCategory.bind(this);
		this.renderMobileBackLink = this.renderMobileBackLink.bind(this);
		this.updateState = this.updateState.bind(this);
		this.state = {
			offline: props.offline,
			open: false, // mobile nav toggle flag
			siteHeight: 'auto',
			navLinks: [],
			submenuIndex: 0, // indicate which submenu to open on mouseover
			showSubmenu: false,
			showCategory: false,
			categoryIndex: 0,
			categoryConfig: [],
			externalLinks: []
		};
	}

	updateState(key, value) {
		this.setState({
			offline: this.state.offline,
			open: key === 'open' ? value : this.state.open,
			siteHeight: key === 'siteHeight' ? value : this.state.siteHeight,
			navLinks: key === 'navLinks' ? value : this.state.navLinks,
			submenuIndex: key === 'submenuIndex' ? value : this.state.submenuIndex, // indicate which submenu to open on mouseover
			showSubmenu: key === 'showSubmenu' ? value : this.state.showSubmenu,
			showCategory: key === 'showCategory' ? value : this.state.showCategory,
			categoryIndex: key === 'categoryIndex' ? value : this.state.categoryIndex,
			categoryConfig: key === 'categoryConfig' ? value : this.state.categoryConfig,
			externalLinks: key === 'externalLinks' ? value : this.state.externalLinks,
		});
	}

	offlineClickHandler(e) {

		if (this.state.offline) { // offline only
			window.location.href = e.target.href ? e.target.href : '/index.html';
		}
	}

	getVersion() {

		return version;
	}

	static getDerivedStateFromProps(props, state) {
		// the function is beeing called after every update
		// so it is necessary to prevent overwriting with props
		// links from fetch call
		return {
			offline: state.offline,
			open: state.open,
			navLinks: state.navLinks.length ? state.navLinks : props.navLinks,
			submenuIndex: state.submenuIndex,
			showSummenu: state.showSubmenu
		}
	}

	componentDidMount() {

		if (window.origin.includes('localhost') || window.origin.includes('test-mleasing11')) {
			console.log('navigation');
		}
		// fetch data here only in cms to aviod double requests
		if (this.state.offline) {
			const navApiPath = environment.offlineApiNavPath + environment.apiNavFileName;

			// get navigation links
			fetch(navApiPath)
				.then(res => res.json())
				.then(json => {

					let navLinks = mapLinks(json, this.state.offline);
					this.updateState('navLinks', navLinks);
				}
			);
		}
		else {
			this.updateState('navLinks', this.state.navLinks);
		}

		let timeout = setTimeout(() => {

			this.updateState('siteHeight', calculateSiteHeight());
			clearTimeout(timeout);
		}, 1000);

		const navCategoriesApiPath = environment.onlineApiNavPath + environment.apiNavCategoriesFileName;

		// get categories order config
		fetch(navCategoriesApiPath)
			.then(res => res.text())
			.then(csv => {

				const arr = csv.split(/\n/g); // get lines
				const data = [];
				let headers = [];

				arr.forEach((line, i) => {

					if (i === 0) {
						headers = line.split(';');
					}
					else {
						let obj = {};

						headers.forEach((header, j) => {

							obj[header] = line.split(';')[j];
						});

						data.push(obj);
					}
				});
				this.updateState('categoryConfig', data);
			}
		);

		const navExternalLinksApiPath = environment.onlineApiNavPath + environment.apiNavExternalLinksFileName;

		// get external links
		fetch(navExternalLinksApiPath)
			.then(res => res.text())
			.then(csv => {

				const arr = csv.split(/\n/g); // get lines
				const data = [];
				let headers = [];

				arr.forEach((line, i) => {

					if (i === 0) {
						headers = line.split(';');
					}
					else {
						let obj = {};

						headers.forEach((header, j) => {

							obj[header] = line.split(';')[j];
						});

						data.push(obj);
					}
				});
				this.updateState('externalLinks', data);
			}
		);
	}

	toggleMobileNav() {

		this.updateState('open', !this.state.open);
	}

	showSubmenu(e, i) {

		this.updateState('submenuIndex', i);

		let timeout = setTimeout(() => {

			this.updateState('showSubmenu', true);
			clearTimeout(timeout);
		}, 0);
	}

	hideSubmenu(e) {

		this.updateState('showSubmenu', false);
	}

	toggleSubmenu(e, i) {

		if (this.state.showSubmenu) {
			this.hideSubmenu(e);
		}
		else {
			this.showSubmenu(e, i);
		}
	}

	openCategory(e, i) {

		this.updateState('showCategory', true);

		let timeout = setTimeout(() => {

			this.updateState('categoryIndex', i);
			clearTimeout(timeout);
		}, 0);
	}

	hideCategory() {

		this.updateState('showCategory', false);
	}

	mapLinkCategories(links) {

		// get rid of home page link
		let navLinks = links.filter(link => link.url !== '/' && link.url !== '/index.html?__disableDirectEdit=false');
		let navArr = [];
		// convert categories to arrays
		navLinks = navLinks.map(link => {

			// remove unnecessary characters from categories
			let category = link.category ? link.category.replace(/\[/g, '').replace(/\]/g, '') : '';

			// convert to arrays
			if (category === '') {
				category = [];
			}
			else {
				category = category
					.split(' ')
					.filter(cat => cat !== '' && cat !== ',' && !cat.match(/:/g))
					.map(cat => cat.replace(environment.cmsExportPath, '').replace('/.categories', ''))
			}

			return {
				url: link.url,
				navText: link.navText,
				category: category,
				nodes: link.nodes ? link.nodes.map(node => {

					// remove unnecessary characters from categories
					let category = node.category ? node.category.replace(/\[/g, '').replace(/\]/g, '') : '';

					// convert to arrays
					if (category === '') {
						category = [];
					}
					else {
						category = category
							.split(' ')
							.filter(cat => cat !== '' && cat !== ',' && !cat.match(/:/g))
							.map(cat => cat.replace(environment.cmsExportPath, '').replace('/.categories', ''))
					}

					return {
						url: node.url,
						navText: node.navText,
						category: category
					};
				}) : []
			};
		});
		navLinks.forEach(link => {

			let category = link.category ? link.category : '';

			// first level link
			if (!category.length || category.length === 1) {
				navArr.push(link);
			}
			else { // standalone second level links
				// if no such category in nav array, create it
				if (!navArr.filter(cat => cat !== category[0]).length) {
					navArr.push({
						url: '',
						category: category[0],
						navText: category[0].replace(/\//g, ''),
						nodes: [{
							url: link.url,
							navText: link.navText,
							category: category[1]
						}]
					});
				}
				else { // otherwise push as nodes
					navArr = navArr.map(item => {

						return {
							url: item.url,
							navText: item.navText,
							category: item.category,
							nodes: [...item.nodes, {
								url: link.url,
								navText: link.navText,
								category: category[1]
							}]
						};
					});
				}
			}
		});
		// add external links here
		navArr = navArr.map(item => {

			if (item.nodes && item.category === '/oferta/') {
				let nodes = [...item.nodes, ...this.state.externalLinks.map(link => {

					return {
						url: link.url,
						navText: link.nazwa,
						category: '/oferta/dodatkowe-rozwiazania/'
					};
				})];

				return {
					category: item.category,
					navText: item.navText,
					nodes: nodes
				};
			}
			else {
				return item;
			}
		});

		return navArr.map(item => {

			return {
				url: item.url,
				navText: item.navText,
				category: item.category,
				nodes: item.nodes.map(node => {

					return {
						url: node.url,
						navText: node.navText,
						category: Array.isArray(node.category) ? node.category[0] : node.category
					};
				})
			};
		});
	}

	renderMobileBackLink() {

		return (
			<MobileBackLink>
				<NavButton buttonType="tertiary" onClick={this.state.showCategory ? this.hideCategory : this.hideSubmenu} >
					<MobileIconWrapper
						style={{left: '-7px', top: '2px'}}
					>
						<Icon
							iconComponent={ChevronLeft}
							primaryColor={IconColor.BLUE}
							title="ikona"
						/>
					</MobileIconWrapper>
					<MobileButtonContent
						color={palette.endeavourBlueUI}
						style={{justifyContent: 'flex-start', textAlign: 'left', paddingLeft: '16px'}}
					>
						powrót
					</MobileButtonContent>
				</NavButton>
			</MobileBackLink>
		);
	}

	generateSubmenu(links, index, category) {

		let linksJsx = '';
		let categories = [];
		let mappedLinks = links.map(link => {

			return {
				url: link.url,
				navText: link.navText,
				category: link.category ? link.category.split('/').filter(cat => cat !== '')[1] : ''
			};
		});

		mappedLinks.forEach(link => {

			if (!categories.includes(link.category)) {
				categories.push(link.category);
			}
		});

		// reorder categories here
		// it is absolutely necessary to find a better way - to export
		if (this.state.categoryConfig && categories.length > 1) {
			categories = this.state.categoryConfig.map(catPos => {

				let asciiCat = catPos.category.split('/')[1]
					.replace('ą', 'a')
					.replace('ć', 'c')
					.replace('ę', 'e')
					.replace('ł', 'l')
					.replace('ń', 'n')
					.replace('ó', 'o')
					.replace('ś', 's')
					.replace('ź', 'z')
					.replace('ż', 'z');

				let filteredCat = categories.filter(cat => cat.match(asciiCat))
				return filteredCat[0];
			});
		}

		linksJsx = categories.map((cat, i) => {

			return (
				<SubmenuColumn key={'submenu-' + i}>
					{i === 0 && this.renderMobileBackLink()}
					{cat && <SubmenuTitle>
						<Text as="h3">{this.state.categoryConfig[i] ? this.state.categoryConfig[i]['nazwa'] : cat}</Text>
					</SubmenuTitle>}
					{cat && <MobileSubmenuTitle open={!this.state.showCategory}>
						<NavButton buttonType="tertiary" onClick={(e) => this.openCategory(e, i)} >
							<MobileIconWrapper>
								<Icon
									iconComponent={ChevronRight}
									primaryColor={IconColor.BLUE}
									title="ikona"
								/>
							</MobileIconWrapper>
							<ButtonContent
								color={palette.zeusBlackUI}
								style={{justifyContent: 'flex-start', textAlign: 'left'}}
							>
								{this.state.categoryConfig[i] ? this.state.categoryConfig[i]['nazwa'] : cat}
							</ButtonContent>
						</NavButton>
					</MobileSubmenuTitle>}
					<Submenu className="submenu" open={this.state.showCategory && this.state.categoryIndex === i || !cat}>{
						mappedLinks
							.filter(link => !link.category || link.category === cat)
							.map((link, j) => {
								return (<MenuItem
									key={'menu-item' + j}
									marginLeft={px(Spacing.SPACE_0)}
									marginBottom={px(Spacing.SPACE_16)}
									marginRight={px(Spacing.SPACE_0)}
								>
									{generateLink(this.props.prefix + link.url, link.navText, {color: palette.zeusBlackUI}, '', this.state.offline)}
								</MenuItem>);
							})
					}</Submenu>
				</SubmenuColumn>
			);
		});

		return (<>
			<SubmenuLayer className="submenu-layer" show={this.state.showSubmenu && this.state.submenuIndex === index}>
				<SubmenuContainer>
					<SubmenuWrapper index={index}>{linksJsx}</SubmenuWrapper>
					<MenuBox category={category} prefix={this.props.prefix} offline={this.props.offline} />
				</SubmenuContainer>
			</SubmenuLayer>
			<Overlay
				className="overlay"
				siteHeight={this.state.siteHeight}
				show={this.state.showSubmenu && this.state.submenuIndex === index}
			>
			</Overlay>
		</>);
	}

	generateNav(links) {

		let linksJsx = '';

		if (links) {
			let mappedLinks = this.mapLinkCategories(links);
			// rewrite aukcje href to external url
			mappedLinks = mappedLinks.map(link => {

				if (link.navText === 'aukcje') {
					return {
						url: 'https://portalaukcyjny.mleasing.pl/ ',
						navText: link.navText,
						category: link.category ? link.category : '',
						nodes: link.nodes
					};
				}
				else {
					return link;
				}
			});

			linksJsx = mappedLinks.map((link, i) => {

				if (link.nodes && link.nodes.length) {
					return (
						<MenuItem key={'menu-item' + i}>
							<NavButton buttonType="tertiary" onClick={(e) => this.toggleSubmenu(e, i)} >
								<ButtonContent
									color={this.state.showSubmenu && this.state.submenuIndex === i ? palette.endeavourBlueUI : palette.zeusBlackUI}
									style={{justifyContent: 'flex-start'}}
								>
									{link.navText.toLowerCase()}
									<IconWrapper>
										<Icon
											iconComponent={this.state.showSubmenu && this.state.submenuIndex === i ? ChevronUp : ChevronDown}
											primaryColor={IconColor.BLUE}
											title="ikona"
										/>
									</IconWrapper>
								</ButtonContent>
							</NavButton>
							<MobileIconWrapper>
								<Icon
									iconComponent={this.state.showSubmenu && this.state.submenuIndex === i ? ChevronLeft : ChevronRight}
									primaryColor={IconColor.BLUE}
									title="ikona"
								/>
							</MobileIconWrapper>
							{this.generateSubmenu(link.nodes, i, link.category && link.category.length ? link.category : link.url)}
						</MenuItem>
					);
				}
				else {
					return (
						<MenuItem key={'menu-item' + i}>
							{generateLink(this.props.prefix + link.url, link.navText, {
								textTransform: 'lowercase',
								color: palette.zeusBlackUI,
								display: 'block',
								width: '100%',
								textAlign: (typeof window !== 'undefined' && window.innerWidth > breakpoints.mobileMax ? 'center' : 'left'),
								lineHeight: 1.1
							}, '', this.state.offline)}
						</MenuItem>
					);
				}
			});
		}

		return <Menu className="menu">{linksJsx}</Menu>;
	}

	render() {
		return (
			<>
				<Stripe height={StripeHeight.HEIGHT_4} />
				<Container>
					<MainNav className="main-nav">
						<Link to="/" onClick={this.offlineClickHandler}>
							{/* the height of mbank logo component does not fit the design */}
							<Logo width="155" height="50" title="logo mLeasing" />
						</Link>
						<NavToggle className="nav-toggle" onClick={this.toggleMobileNav}>
							<Icon iconComponent={this.state.open ? Close : HamburgerMenu} primaryColor={IconColor.BLUE} title="menu" />
						</NavToggle>
						<NavWrapper open={this.state.open} offline={this.state.offline}>
							{this.generateNav(this.state.navLinks)}
							<Actions offline={this.state.offline} prefix={this.props.prefix} />
						</NavWrapper>
					</MainNav>
				</Container>
			</>
		);
	}
}

export default Navigation;
